<template>
  <!-- DOC https://vue-treeselect.js.org/ -->
  <div v-if="categories_fetching" class="px-3 py-2 border rounded text-muted font-italic">Chargement des catégories....</div>
  <vue-tree-select v-else
    :multiple="false"
    :options="selectOptions"
    :placeholder="placeholder"
    :disabled="disabled"
    v-model="category"
    search-nested
    @input="categoryChange"
  >
    <label slot="option-label" slot-scope="{ node }">
      <div class="d-flex align-items-center">
        <span>{{ node.label }}</span>
        <small class="text-muted ml-1">#{{node.id}}</small>
        <small v-if="node.id" class="ml-2">
          <i v-if="node.raw.is_active" class="fas fa-eye" :class="node.raw.parent_active ? 'text-success' : 'text-warning'" />
          <i v-if="!node.raw.is_active" class="text-danger fas fa-eye-slash" />
          <i v-if="node.raw.is_locked" class="fas fa-lock text-danger ml-1" />
        </small>
        <b-badge v-if="node.raw.category_content_type == 1" variant="danger" class="ml-1">recette</b-badge>
        <small v-if="!node.children || !node.children.length" class="text-muted ml-1">[{{node.raw.number_of_products}}]</small>
      </div>
    </label>
    <div slot="value-label" slot-scope="{ node }">
      <span v-if="node.raw.root" class="smallxs text-dark">{{node.raw.root}}</span>
      <small v-if="node.raw.root && !node.raw.parent" class="px-1">/</small>
      <small v-if="node.raw.parent" class="text-muted"> / {{node.raw.parent}}</small>
      <strong class="smallx">{{node.label}}</strong>
      <small class="text-muted ml-1">#{{node.id}}</small>
      <small class="ml-2">
        <i v-if="node.raw.is_active" class="fas fa-eye" :class="node.raw.parent_active ? 'text-success' : 'text-warning'" />
        <i v-else class="text-danger fas fa-eye-slash" />
        <i v-if="node.raw.is_locked" class="fas fa-lock text-danger ml-1" />
      </small>
      <b-badge v-if="node.raw.category_content_type == 1" variant="danger" class="ml-1">recette</b-badge>
      <small v-if="!node.children || !node.children.length" class="text-muted ml-2">[{{node.raw.number_of_products}}]</small>
    </div>
  </vue-tree-select>
</template>

<script>
import store from '@/store'
import eventEmitter from '@/event-emitter'
import VueTreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'CategoriesTreeSelect',
  components: {
    VueTreeSelect
  },
  props: {
    site: { type: Number, default: () => 1 },
    target: String,
    selected: [Number, String],
    content_type: [Number, String],
    excluded: { type: Array, default: () => [] },
    active: { type: Boolean, default: () => false },
    disabled: { type: Boolean, default: () => false },
    context: String,
    placeholder: { type: String, default: () => 'Indifférent' }
  },
  data () {
    return {
      categories_fetching: true,
      category: this.selected,
      selectOptions: [{ id: '', label: this.placeholder }]
    }
  },
  watch: {
    site () {
      this.computeSelectOptions()
    },
    selected () {
      this.category = this.selected
    }
  },
  mounted () {
    eventEmitter.on('categories:fetched', this.computeSelectOptions)
    if (store.state.categoriesFetched === 'done') {
      this.computeSelectOptions()
    } else {
      store.fetchCategories()
    }
  },
  beforeDestroy () {
    eventEmitter.off('categories:fetched', this.computeSelectOptions)
  },
  methods: {
    disabledNode (node) {
      if (!node.id) return false

      return node.is_deleted ||
        node.is_locked ||
        node.category_content_type !== 0 ||
        this.excluded.indexOf(node.id) !== -1 ||
        (this.active && !node.is_active) ||
        (this.context === 'edit-section' && !(node.children && node.children.length)) ||
        (this.context === 'edit-category' && node.number_of_products > 0)
    },
    disabledNodes (categories) {
      categories.forEach(o => {
        o.isDisabled = this.disabledNode(o)
        if (o.children) this.disabledNodes(o.children)
      })
    },
    computeSelectOptions () {
      this.categories_fetching = false
      let _categories = store.getCategories('treeselect', this.site)
      this.disabledNodes(_categories)
      this.selectOptions = _categories
    },
    categoryChange () {
      this.$emit('categoryChange', { target: this.target, value: this.category })
    }
  }
}
</script>
